"use client";
import { HTMLAttributes } from "react";
import { AiOutlineInfoCircle } from "../../../react-icons/ai";
import { Tooltip } from "../tooltip/Tooltip";

interface Props extends HTMLAttributes<HTMLDivElement> {
  children: any | any[];
  iconSize?: number;
  iconColor?: string;
};

export function InfoBulle({ children, iconSize = 20, iconColor = "var(--color-text)", className, ...props }: Props) {
  return (
    <Tooltip legend={children} className={className} position="bottom" {...props}>
      <AiOutlineInfoCircle size={iconSize} color={iconColor} />
    </Tooltip>
  );
}
