"use client";
import classNames from "classnames";
import { useRef, useState, type JSX } from "react";

interface ToolTipProps extends React.HTMLAttributes<HTMLDivElement> {
  children?: JSX.Element | JSX.Element[] | string;
  legend: string | JSX.Element | JSX.Element[];
  position?: "top" | "left" | "bottom" | "right";
  tooltipProps?: React.HTMLAttributes<HTMLDivElement>;
}

export function Tooltip({ children, legend, position = "bottom", tooltipProps, ...props }: ToolTipProps) {
  const container = useRef(null);
  const ref = useRef(null);
  const [tooltipPosition, setTooltipPosition] = useState({ top: 0, left: 0 });

  const handleMouseEnter = () => {
    if (legend && ref?.current) {
      if (container?.current) {
        const parentRect = container?.current.getBoundingClientRect();

        let tooltipX = 0;
        let tooltipY = 0;

        switch (position) {
          case "top":
            tooltipX = parentRect.left + parentRect.width / 2;
            tooltipY = parentRect.top;
            break;

          case "bottom":
            tooltipX = parentRect.left + parentRect.width / 2;
            tooltipY = parentRect.bottom;
            break;

          case "left":
            tooltipX = parentRect.left;
            tooltipY = parentRect.top + parentRect.height / 2;
            break;

          case "right":
            tooltipX = parentRect.right;
            tooltipY = parentRect.top + parentRect.height / 2;
            break;

          default:
            tooltipX = parentRect.left + parentRect.width / 2;
            tooltipY = parentRect.top;
            break;
        }
        const { scrollX: scrollWidth, scrollY: scrollHeight } = window;

        setTooltipPosition({ top: tooltipY + scrollHeight, left: tooltipX + scrollWidth });
      }
      ref?.current?.showPopover && ref?.current?.showPopover();
    }
  };

  const handleMouseLeave = () => {
    if (legend && ref?.current) {
      ref?.current?.hidePopover && ref?.current?.hidePopover();
    }
  };

  return (
    <>
      {legend ? (
        <div
          ref={container}
          {...props}
          className={classNames(props?.className, "tooltip-container")}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          onClick={(e) => {
            e.stopPropagation;
            props?.onClick && props?.onClick(e);
          }}
        >
          {children}

          <span
            style={{ ...tooltipPosition, ...tooltipProps?.style }}
            ref={ref}
            // @ts-ignore
            popover="manual"
            className={classNames("tooltip", position)}
          >
            {legend}
          </span>
        </div>
      ) : (
        children
      )}
    </>
  );
}
